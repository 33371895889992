















































import { FormBasicInfo, FormTable } from "@/components/GoodsReceiptChecklist";
import { useBlob, useReceiveItem } from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Mode } from "@/models/enums/global.enum";
import { LogisticReceivingItemStateEnum } from "@/models/enums/receive-items.enum";
import {
  ReceivingItemCreateRequestDto,
  ReceivingItemUpdateRequestDto,
} from "@/models/interface/receive-item";
import { FormValue } from "@/store/goodsReceiptChecklist.store";
import { FormModel } from "ant-design-vue";
import printJS from "print-js";
import { Component, Mixins, Prop, Ref } from "vue-property-decorator";
import { mapActions, mapGetters } from "vuex";

/**
 * notes
 * - setelah submit, status GR jadi UN_BILLED
 */

/**
 * status DRAFT
 * - bisa submit, save draft, add row, delete row, print,
 * ubah supplier do number, supplier bill address,
 * selain itu disabled
 *
 * - tidak bisa ubah product code, name, part number
 * merk, qty po, uom
 */

/**
 * status WAIT FOR APPROVAL
 * - bisa print, ubah supplier do number, supplier bill address
 * add row, delete row, submit
 *
 * - tidak bisa ubah product code, name, part number
 * merk, qty po, uom
 */

@Component({
  beforeRouteLeave(_to, _from, next) {
    this.resetStore();
    next();
  },
  components: {
    FormBasicInfo,
    FormTable,
  },
  computed: {
    ...mapGetters({
      form: "goodsReceiptChecklistStore/getForm",
    }),
  },
  methods: {
    ...mapActions({
      resetStore: "goodsReceiptChecklistStore/resetStore",
    }),
  },
})
export default class FormPage extends Mixins(MNotificationVue) {
  @Ref("formBasicInfo")
  formBasicInfo: any;

  @Prop({ required: false, default: "", type: String })
  id!: string; // GR secureId

  form!: FormValue;
  resetStore!: () => void;

  loading = {
    print: false,
    draft: false,
    update: false,
    submit: false,
  };

  get isModeEdit(): boolean {
    return this.$route.meta?.mode === Mode.EDIT;
  }

  get isModeCreate(): boolean {
    return this.$route.meta?.mode === Mode.CREATE;
  }

  get title(): string {
    const mode = this.$route.meta?.mode || "";
    const createStr = this.$t("lbl_create_x", {
      x: this.$t("lbl_goods_receipt_checklist"),
    });
    const editStr = this.$t("lbl_goods_receipt_checklist_edit");

    return mode === Mode.CREATE ? createStr.toString() : editStr.toString();
  }

  validateForm(action: "draft" | "submit" | "update"): void {
    const form: FormModel = this.formBasicInfo.form;
    form.validate(valid => {
      if (!valid) {
        this.showNotifWarning("notif_validation_error");
        return;
      }

      if (action === "draft") {
        this.handleSaveDraft();
      } else if (action === "submit") {
        if (this.isModeCreate) {
          this.submitNewDoc();
        } else if (this.isModeEdit && this.id) {
          this.submitDoc(this.id);
        }
      } else if (action === "update") {
        this.handleUpdate(this.id);
      }
    });
  }

  /**
   * @description
   * submit dokumen yang sudah dibuat
   */
  submitDoc(id: string): void {
    const { submitReceiveItem, mapFormChecklistToUpdateDto } = useReceiveItem();
    const req: ReceivingItemUpdateRequestDto = mapFormChecklistToUpdateDto(
      this.form
    );
    this.loading.submit = true;
    submitReceiveItem(id, req)
      .then(res => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  /**
   * @description
   * membuat dokumen baru dengan status `Submitted`
   */
  submitNewDoc(): void {
    const { create, mapFormChecklistToCreateDto } = useReceiveItem();
    const req: ReceivingItemCreateRequestDto = mapFormChecklistToCreateDto(
      this.form
    );

    // set status to `Submitted`
    req.status = LogisticReceivingItemStateEnum.SUBMITTED;

    this.loading.submit = true;
    create(req)
      .then(res => {
        this.showNotifSuccess("notif_submit_success", {
          documentNumber: res.documentNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.submit = false;
      });
  }

  handleSaveDraft(): void {
    const { createDraft, mapFormChecklistToCreateDto } = useReceiveItem();
    const req: ReceivingItemCreateRequestDto = mapFormChecklistToCreateDto(
      this.form
    );
    this.loading.draft = true;
    createDraft(req)
      .then(() => {
        this.showNotifSuccess("notif_create_success");
        this.handleBack();
      })
      .finally(() => {
        this.loading.draft = false;
      });
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    const { printGrChecklistReport } = useReceiveItem();
    this.loading.print = true;
    printGrChecklistReport(this.id)
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }

  handleUpdate(id: string): void {
    const { updateReceiveItem, mapFormChecklistToUpdateDto } = useReceiveItem();
    const req: ReceivingItemUpdateRequestDto = mapFormChecklistToUpdateDto(
      this.form
    );
    this.loading.update = true;
    updateReceiveItem(id, req)
      .then(res => {
        this.showNotifSuccess("notif_update_success", {
          documentNumber: res.checklistNumber,
        });
        this.handleBack();
      })
      .finally(() => {
        this.loading.update = false;
      });
  }

  handleBack(): void {
    this.$router.push({
      name: "logistic.goods-receipt-checklist",
    });
  }
}
